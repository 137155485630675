<template>
  <header id="masthead" class="header cmt-header-style-03">
    <!-- top_bar -->
    <div class="top_bar cmt-bgcolor-darkgrey cmt-textcolor-white clearfix">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 d-flex flex-row align-items-center">
            <div class="top_bar_icon"><i class="ti ti-map-alt"></i></div>
            <div class="top_bar_contact_item">
              <div class="top_bar_icon"></div>
              {{ headerData.company_address }}
            </div>
          </div>
          <div class="col-lg-5 d-flex flex-row align-items-center">
            <div class="top_bar_contact_item ml-auto">
              <div class="top_bar_icon"><i class="ti ti-email"></i></div>
              <a href="javascript:void(0)">{{ headerData.company_info_mailid }}</a>
            </div>
            <div class="top_bar_contact_item">
              <div class="top_bar_icon"><i class="ti ti-mobile"></i></div>
              {{ headerData.company_contact_no_1 }}
            </div>
            <div class="top_bar_contact_item">
              <div class="top_bar_social">
                <ul class="social-icons">
                  <li>
                    <a
                      class="tooltip-bottom"
                      target="_blank"
                      href="javascript:void(0)"
                      data-tooltip="Facebook"
                      tabindex="-1"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      class="tooltip-bottom"
                      target="_blank"
                      href="javascript:void(0)"
                      data-tooltip="Twitter"
                      tabindex="-1"
                      ><i class="fa fa-twitter"></i
                    ></a>
                  </li>
                  <!-- <li>
                    <a
                      class="tooltip-bottom"
                      target="_blank"
                      href="javascript:void(0)"
                      data-tooltip="Flickr"
                      tabindex="-1"
                      ><i class="fa fa-youtube"></i
                    ></a>
                  </li> -->
                  <!-- <li>
                    <a
                      class="tooltip-bottom"
                      target="_blank"
                      href="javascript:void(0)"
                      data-tooltip="Linkedin"
                      tabindex="-1"
                      ><i class="fa fa-linkedin"></i
                    ></a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- top_bar end-->
    <!-- site-header-menu -->
    <div
      id="site-header-menu"
      class="site-header-menu cmt-textcolor-dark"
      style="background-color: #fff;"
    >
      <div class="site-header-menu-inner cmt-stickable-header">
        <div class="container">
          <!--site-navigation -->
          <div class="site-navigation d-flex flex-row">
            <!-- site-branding -->
            <div class="site-branding mr-auto">
              <router-link
                class="home-link"
                to="/"
                title="Green Earth Marine Solution"
                rel="home"
              >
                <img
                  id="logo-img"
                  class="img-center"
                  :src="getItem(headerData.logo)"
                  alt="logo-img"
                />
              </router-link>
            </div>
            <!-- site-branding end -->
            <div class="btn-show-menu-mobile menubar menubar--squeeze">
              <span class="menubar-box">
                <span class="menubar-inner"></span>
              </span>
            </div>
            <!-- menu -->
            <nav class="main-menu menu-mobile" id="menu">
              <ul class="menu">
                <li class="menu-item active">
                  <router-link to="/" class="menu-link">Home</router-link>
                </li>
                <li class="menu-item">
                  <router-link to="/about" class="mega-menu-link"
                    >About Us</router-link
                  >
                </li>
                <li class="mega-menu-item">
                  <a
                    href="javascript:void(0)"
                    class="mega-menu-link menu-mobile1"
                    >Our Services</a
                  >
                  <ul class="mega-submenu">
                    <li class="menu-item">
                      <router-link to="/shiprepairs" class="menu-link"
                        >Ship Repairs</router-link
                      >
                    </li>

                    <li class="menu-item">
                      <router-link to="/marinesurvey" class="menu-link"
                        >Marine Survey</router-link
                      >
                    </li>

                    <li class="menu-item">
                      <router-link to="/shipstoresupplier" class="menu-link"
                        >Ship Store Supplier</router-link
                      >
                    </li>
                  </ul>
                </li>
                <li class="menu-item">
                  <router-link to="/wastemanagement" class="menu-link"
                    >Waste Management</router-link
                  >
                </li>
                <li class="mega-menu-item">
                  <router-link to="/contact" class="menu-link"
                    >Contact Us</router-link
                  >
                </li>
              </ul>
            </nav>

            <div
              class="header_extra d-flex flex-row align-items-center justify-content-end"
            >
              <a
                class="cmt-btn cmt-btn-size-md cmt-btn-shape-square cmt-btn-style-border cmt-btn-color-dark"
                href="javascript:void(0)"
                data-toggle="modal"
                data-target="#exampleModal"
                >Request Quote</a
              >
            </div>
          </div>
          <!-- site-navigation end-->
        </div>
      </div>
    </div>
    <!-- site-header-menu end-->
  </header>
  <!--header end-->

  <!-- Modal -->
  <div
    class="modal fade bd-example-modal-lg"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <form
          id="contact-form"
          @submit="sendmail()"
          action="javascript:void(0)"
          class="contact-form"
          method="POST"
        >
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Get Quick Quote</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <lable>First Name</lable>
                    <input
                      type="text"
                      name="fname"
                      id="fname"
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <lable>Last Name</lable>
                    <input
                      type="text"
                      name="lname"
                      id="lname"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <lable>Your Email</lable>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <lable>Your phone Number</lable>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Your phone Number"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <lable>Subject</lable>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <lable>Message</lable>
                    <textarea
                      cols="3"
                      name="message"
                      id="message"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="submit cmt-btn cmt-btn-size-md cmt-btn-shape-square cmt-btn-style-fill cmt-btn-color-skincolor"
              type="submit"
            >
              Send Message
            </button>
            <!-- <button
              type="clear"
              class="rev-btn cmt-btn cmt-btn-size-md cmt-btn-shape-square cmt-btn-style-fill cmt-btn-color-skincolor cmt-btn-color-cancel"
              @click="clear()"
            >
              Cancel
            </button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { Email } from "../../assets/js/smtp.js";
import swal from "sweetalert2";
window.Swal = swal;
import data from "../../Data/data.json";
export default {
  name: "Header",
  date() {
    return {
      headerData: "",
    };
  },
  created() {
    this.headerData = data.company_detailes;
  },
  mounted() {
    var menu = {
      initialize: function() {
        this.Menuhover();
      },

      Menuhover: function() {
        var getNav = $("nav.main-menu"),
          getWindow = $(window).width(),
          getHeight = $(window).height(),
          getIn = getNav.find("ul.menu").data("in"),
          getOut = getNav.find("ul.menu").data("out");
        console.log(getWindow, getHeight, getIn, getOut);

        if (matchMedia("only screen and (max-width: 1200px)").matches) {
          // Enable click event
          $("nav.main-menu ul.menu").each(function() {
            // Dropdown Fade Toggle
            $("a.mega-menu-link", this).on("click", function(e) {
              e.preventDefault();
              var t = $(this);
              t.toggleClass("active")
                .next("ul")
                .toggleClass("active");
            });

            // Megamenu style
            $(".megamenu-fw", this).each(function() {
              $(".col-menu", this).each(function() {
                $(".title", this).off("click");
                $(".title", this).on("click", function() {
                  $(this)
                    .closest(".col-menu")
                    .find(".content")
                    .stop()
                    .toggleClass("active");
                  $(this)
                    .closest(".col-menu")
                    .toggleClass("active");
                  return false;
                  // e.preventDefault();
                });
              });
            });
          });
        }
      },
    };

    $(".btn-show-menu-mobile").on("click", function() {
      $(this).toggleClass("is-active");
      $(".menu-mobile").toggleClass("show");
      return true;
      // e.preventDefault();
    }); 

    // Initialize
    $(document).ready(function() {
      menu.initialize();
    });
  }, 
  methods: {
    getItem(pic) {
      if (pic) {
        return require("../../assets/images/" + pic);
      } else {
        return null;
      }
    },
    sendmail() {
      var fname = $("#fname").val();
      var lname = $("#lname").val();
      var email = $("#email").val();
      var phone = $("#phone").val();
      var subject = $("#subject").val();
      var message = $("#message").val();
      var Body =
        "First Name: " +
        fname +
        "<br>Last Name: " +
        lname +
        "<br>Phone: " +
        phone +
        "<br>Subject: " +
        subject +
        "<br>Email: " +
        email +
        "<br>Message: " +
        message;

      Email.send({
        SecureToken: "b6183983-9783-4396-8d62-4086d1a63a39",
        To: "operation@greenearthmarine.com",
        From: "greenearthmarinesolutions@gmail.com",
        Subject: "New message on contact from " + fname,
        Body: Body,
      }).then((message) => {
        if (message == "OK") {
          new swal(
            "Thank You For Message Us!",
            "We will contact you as soon as possible!",
            "success"
          );
          document.getElementById("contact-form").reset();
        } else {
          console.error(message);
          new swal("Oops!", "Something went wrong, please try again!", "error");
          document.getElementById("contact-form").reset();
        }
      });
    },
    // clear() {
    //   // alert("called");
    //   document.getElementById("contact-form").reset();
    // },
  },
};
</script>

<style>
.cmt-btn-color-cancel {
  background-color: #e21f2f !important;
}
.cmt-btn-color-cancel:hover {
  background-color: #e21f2f !important;
}

/* style for navbar */
</style>
