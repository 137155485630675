<template>
    <mainSlider/>
    <AboutUs/>
    <Services/>
    <Features/>
    <WestManagement/>
</template>

<script>
import mainSlider from '../components/mainSlider.vue'
import AboutUs from '../components/about.vue'
import Services from '../components/services_with_slickslide.vue'
import Features from '../components/feature.vue'
import WestManagement from '../components/waste management/aboutWestManagement.vue'
export default {
  name: 'Home',
  components:{
    mainSlider,
    AboutUs,
    Services,
    Features,
    WestManagement
  },
  data() {
    return{}
  }
}
</script>
