<template>
   <div class="layout_wrapper">
    <Header />
    <router-view/>
    <Footer />
   </div>
</template>

<script>
import Header from '../components/common/Header.vue'
import Footer from '../components/common/Footer.vue'
export default {
    name: "Frame",
    components: {
        Header,
        Footer
    },
    data() {
        return{}        
    },
}
</script>

<style scoped>

</style>