<template>
  <section
    class="cmt-row timeline-section cmt-bgcolor-darkgrey cmt-bg cmt-bgimage-yes bg-img5 clearfix">
    <div class="cmt-row-wrapper-bg-layer cmt-bg-layer"></div>
    <div class="container">     
      <div class="row">
        <div class="col-lg-12"> 
          <div class="section-title title-style-center_text">
            <div class="title-header"> 
              <h2 class="title">
                {{ featureData.title }}
              </h2>
            </div>
            <p style="margin-top: 15px;">
              {{ featureData.desc }}
            </p> 
            <br /> 
            <router-link
              class="cmt-btn cmt-btn-size-md cmt-btn-shape-square cmt-btn-style-border cmt-btn-color-white"
              to="/contact">Contact Us
            </router-link >
          </div> 
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from '../Data/data.json'
export default {
  data() {
    return {
      featureData: ""
    };
  },
  created() {
    this.featureData = data.homefeatire
  }
};
</script>
