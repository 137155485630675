import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import JQuery from 'jquery'
window.$ = window.JQuery = JQuery

import VueSweetalert2 from 'vue-sweetalert2'; 
import swal from 'sweetalert2';
window.Swal = swal;

import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/animate.css'
import '../src/assets/css/font-awesome.css'
import '../src/assets/css/themify-icons.css'
import '../src/assets/css/flaticon.css'
import '../src/assets/css/slick.css'
import '../src/assets/css/main.css'
import '../src/assets/css/megamenu.css'
import '../src/assets/css/responsive.css'

import '../src/assets/js/jquery.min.js'
import '../src/assets/js/tether.min.js'
import '../src/assets/js/bootstrap.min.js'
import '../src/assets/js/jquery.easing.js'
// import '../src/assets/js/jquery-waypoints.js'
// import '../src/assets/js/jquery-validate.js'
// import '../src/assets/js/jquery.prettyPhoto.js'
import '../src/assets/js/slick.min.js'
import '../src/assets/js/numinate.min.js'
import '../src/assets/js/imagesloaded.min.js'
// import '../src/assets/js/jquery-isotope.js'
import '../src/assets/js/main.js'
import '../src/assets/revolution/js/slider.js'
// import '../src/assets/revolution/js/revolution.tools.min.js'
// import '../src/assets/revolution/js/rs6.min.js'
import '../src/assets/js/smtp.js'

createApp(App).use(router).use(VueSweetalert2).mount('#app')
