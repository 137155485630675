import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Frame from '../Layouts/Frame.vue'

const routes = [
  {
    path: "",
    component: Frame,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home 
      },
      {
        path: '/about',
        name: 'About', 
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/services',
        name: 'Our Services', 
        component: () => import(/* webpackChunkName: "services" */ '../views/services.vue')
      },
      {
        path: '/wastemanagement',
        name: 'Waste Management', 
        component: () => import(/* webpackChunkName: "wastemanagement" */ '../views/wastemanagement.vue')
      },
      // {
      //   path: '/gallery',
      //   name: 'Gallery', 
      //   component: () => import(/* webpackChunkName: "gallery" */ '../views/gallery.vue')
      // },
      {
        path: '/contact',
        name: 'Contact', 
        component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue')
      },
      {
        path: '/marinesurvey',
        name: 'Marine Survey', 
        component: () => import(/* webpackChunkName: "marinesurvey" */ '../views/marinesurvey.vue')
      },
      {
        path: '/shiprepairs',
        name: 'Ship Repairs', 
        component: () => import(/* webpackChunkName: "shiprepairs" */ '../views/shiprepairs.vue')
      },
      {
        path: '/shipstoresupplier',
        name: 'Ship Store Supplier', 
        component: () => import(/* webpackChunkName: "shipstoresupplier" */ '../views/shipstoresupplier.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // scrollBehavior (to, from, savedPosition) {
    // return desired position
    // console.log(to, from, savedPosition);
    return { top: 0 };
  }
})

export default router
