<template> 
  <footer class="footer widget-footer cmt-bgcolor-grey clearfix">
    <div
      class="first-footer bg-img6 cmt-bg cmt-bgimage-yes cmt-bgcolor-darkgrey"
    >
      <div class="cmt-row-wrapper-bg-layer cmt-bg-layer"></div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-4 widget-area">
            <div class="widget widget-out-link clearfix">
              <i class="ti ti-mobile"></i>
              <h3 class="widget-title">Have a question? call us now</h3>
              <p>
                {{ footerData.company_contact_no_1 }}
                <br />{{ footerData.company_contact_no_2 }}
              </p>
              <router-link
                class="cmt-btn btn-inline cmt-btn-size-sm cmt-btn-color-white"
                to="/contact"
                >Contact Us</router-link
              >
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 widget-area">
            <div class="widget widget-out-link clearfix">
              <i class="ti ti-email"></i>
              <h3 class="widget-title">Need support? Drop us an email</h3>
              <p class="mb-0">{{ footerData.company_info_mailid }}</p>
              <p>{{ footerData.company_supply_mailid }}</p>
              <router-link
                class="cmt-btn btn-inline cmt-btn-size-sm cmt-btn-color-white"
                to="/contact"
                >Our Form</router-link
              >
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 widget-area">
            <div class="widget widget-out-link clearfix">
              <i class="ti ti-timer"></i>
              <h3 class="widget-title">We are open on</h3>
              <p>Mon – Sat 10:00 am– 08:00 pm<br />Sunday – Closed</p>
              <router-link
                class="cmt-btn btn-inline cmt-btn-size-sm cmt-btn-color-white"
                to="/contact"
                >Read More</router-link
              >
            </div>
          </div>
        </div> 
      </div>
    </div>
    <div class="second-footer">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
            <div class="widget widget_text clearfix">
              <div class="footer-logo">
                <img
                  id="footer-logo-img"
                  class="img-center"
                  :src="getItem(footerData.logo)"
                  alt=""
                />
              </div>
              <div class="textwidget widget-text">
                <p>
                  {{ footerData.company_short_intro }}
                </p>
              </div>
              <div class="cmt-horizontal_sep width-100 mt-25 mb-25">
                <span class="sep_holder"><span class="sep_line"></span></span>
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-md mb-0"
                >
                  <i class="fa fa-files-o"></i>
                </div>
                <div class="pl-15">
                  <h5 class="mb-0">Get Free Estimates</h5>
                  <div class="desc cmt-textcolor-skincolor">
                    {{ footerData.company_contact_no_1 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-2 widget-area">
            <div class="widget widget_nav_menu clearfix">
              <h3 class="widget-title">Quick Links</h3>
              <ul id="menu-footer-quick-links">
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/about">About Us</router-link></li>
                <li><router-link to="/shiprepairs">Ship Repairs</router-link></li>
                <li><router-link to="/marinesurvey">Marine Survey</router-link></li>
                <li><router-link to="/shipstoresupplier">Ship Store Supplier</router-link></li>
                <li><router-link to="/wastemanagement">Waste Management</router-link></li>
                <!-- <li><router-link to="/gallery">Gallery</router-link></li> -->
                <li><router-link to="/contact">Contact Us</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
            <div class="widget style2 widget-out-link clearfix">
              <h3 class="widget-title">Gallery</h3>
              <div class="row">
                <div class="gallery-cls" v-for="item in galleryImg" :key="item.id">
                  <img
                    :src="getGalleryImg(item.image)"
                    class="img-fluid"
                    alt="post-img"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
            <div class="widget widget-social clearfix">
              <h3 class="widget-title">Address</h3>
              <div>
                <span class="cmt-textcolor-skincolor mr-2"
                  ><i class="fa fa-map-marker"></i></span
                >{{ footerData.company_address }}
              </div>
              <div class="social-icons circle mt-30">
                <ul class="list-inline">
                  <li class="social-facebook">
                    <a
                      class="tooltip-top"
                      target="_blank"
                      href="#"
                      data-tooltip="Facebook"
                      ><i class="ti ti-facebook"></i
                    ></a>
                  </li>
                  <!-- <li class="social-twitter">
                    <a
                      class="tooltip-top"
                      target="_blank"
                      href="#"
                      data-tooltip="Linkedin"
                      ><i class="ti ti-linkedin"></i
                    ></a>
                  </li> -->
                  <!-- <li class="social-instagram">
                    <a
                      class="tooltip-top"
                      target="_blank"
                      href="#"
                      data-tooltip="Google"
                      ><i class="ti ti-youtube"></i
                    ></a>
                  </li> -->
                  <li class="social-twitter">
                    <a
                      class="tooltip-top"
                      target="_blank"
                      href="#"
                      data-tooltip="twitter"
                      ><i class="ti ti-twitter-alt"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-footer-text">
      <div class="container">
        <div class="row copyright">
          <div class="col-lg-12">
            <span
              >Copyright © {{ year }} all rights reserved
              <router-link to="/" class="cmt-textcolor-white"
                >{{ footerData.company_name }} </router-link
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </footer> 

  <!--back-to-top start-->
  <router-link id="totop" to="/">
    <i class="fa fa-angle-up"></i>
  </router-link>
  <!--back-to-top end-->
</template>

<script>
import data from '../../Data/data.json'
export default {
  name: "Footer",
  data() {
    return {
      footerData: "",
      galleryImg: "",
      year: ''
    };
  },
  created(){
    this.footerData = data.company_detailes
    this.galleryImg = data.galleryImages
  },
  mounted(){
    this.getYear()
  },
  methods: {
    getItem(pic) {
      if (pic) {
        return require("../../assets/images/" + pic) 
      } else {
        return null
      }
    },
    getGalleryImg(pic) {
      if(pic) {
        return require("../../assets/images/gallery/" + pic)
      } else {
        return null
      }
    },
    getYear(){
      const d = new Date();
      this.year = d.getFullYear();
      // console.log("this is curent year", this.year)
    }
  }
};
</script> 
