<template>
  <section class="cmt-row about_fea-section clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-7 col-sm-9 mt-0 mx-auto">
          <div class="ttm_single_image-wrapper">
            <img
              class="img-fluid"
              :src="getItem(aboutData.about_img)"
              alt="image"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class="pt-10 res-991-pt-30">
            <div class="section-title">
              <div class="title-header">
                <h5>{{ aboutData.subtitle }}</h5>
                <h2 class="title">{{ aboutData.title }}</h2>
              </div>
              <div class="heading-seperator"><span></span></div>
            </div>
            <p v-for="item in aboutData.desc" :key="item.id">
              {{ item.row }}
            </p> 
            <div class="row">
              <div class="col-md-6">
                <div class="mt-15">
                  <h5>
                    <i class="fa fa-long-arrow-right mr-2"></i>{{ ourVission.title }}
                  </h5>
                  <p>
                    {{ ourVission.desc }}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mt-15">
                  <h5>
                    <i class="fa fa-long-arrow-right mr-2"></i>{{ ourMission.title }}
                  </h5>
                  <ul v-for="item in ourMission.feature_category" :key="item.id">
                    <li>{{ item.title }}</li> 
                  </ul>
                </div>
              </div>
            </div>
            <router-link
              class="cmt-btn cmt-btn-size-md cmt-btn-shape-square cmt-btn-style-border cmt-btn-color-dark mt-15"
              to="/about"
              v-if="routeName == true">Read More</router-link
            >
          </div>
        </div>
      </div>
      <div class="mt-60 mb-50 res-991-mt-40 res-991-mb-30" v-if="routeName == false">
        <span class="cmt-horizontal_sep"></span>
      </div>
      <div class="row featuredbox-number" v-if="routeName == false">
        <div class="col-md-4" v-for="item in aboutData.about_characteristics" :key="item.id">
          <div
            class="featured-icon-box icon-align-before-content icon-ver_align-top mb-20"
          >
            <div class="featured-icon">
              <div
                class="cmt-icon cmt-icon_element-fill cmt-icon_element-color-grey cmt-icon_element-size-xs cmt-icon_element-style-rounded"
              >
                <i class="cmt-num ti-info"></i>
              </div>
            </div>
            <div class="featured-content">
              <div class="featured-title">
                <h5>{{ item.title }}</h5>
              </div>
              <div class="featured-desc">
                <p>
                  {{ item.desc }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from '../Data/data.json'
export default {
  data() {
    return {
      aboutData: "",
      ourVission: "",
      ourMission: "",
      routeName: false
    };
  },
  created(){
    this.aboutData = data.about_us
    this.ourVission = this.aboutData.about_features[0]
    this.ourMission = this.aboutData.about_features[1]
  },
  mounted(){
    this.getRoute()
  },
  methods:{
    getItem(pic){
      if(pic) {
        return require("../assets/images/" + pic)
      } else {
        return null
      }
    },
    getRoute(){
      this.routeName = this.$route.name;
      if(this.routeName == "Home"){
        this.routeName = true
      } else {
        this.routeName = false
      }
    }
  }
};
</script>
