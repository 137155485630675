<template>
  <section
    class="cmt-row res-991-mt-0 clearfix cmt-bgcolor-grey"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-9 col-lg-9 col-md-12">
          <div class="section-title style2">
            <div class="title-header">
              <h5>{{ serviceData.subtitle }}</h5>
              <h2 class="title">{{ serviceData.title }}</h2>
            </div>
            <div class="title-desc">
              {{ serviceData.short_desc }}
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12">
          <router-link
            class="cmt-btn cmt-btn-size-md cmt-btn-shape-square cmt-btn-style-border cmt-btn-color-dark mb-30 float-lg-right"
            to="/shiprepairs"
            >More Services</router-link
          >
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="res-991-ml-0">
            <div
              class="row slick_slider cmt-boxes-spacing-20px mb_20"
              data-slick='{"slidesToShow": 3, "slidesToScroll": 3, "arrows":false, "dots":false, "autoplay":true, "centerMode":true, "centerPadding":0, "infinite":true, "initialSlide":1, "responsive": [{"breakpoint":1100,"settings":{"slidesToShow": 3}} , {"breakpoint":777,"settings":{"slidesToShow": 2}},
                            {"breakpoint":590,"settings":{"slidesToShow": 1}}]}'
            >
              <div class="cmt-box-col-wrapper col-lg-4" v-for="item in serviceData.services" :key="item.id">
                <div
                  class="featured-imagebox featured-imagebox-services style1"
                >
                  <div class="featured-content featured-content-services">
                    <div class="featured-title">
                      <h5>{{ item.title }}</h5>
                    </div>
                    <div class="featured-thumbnail">
                      <img
                        class="img-fluid"
                        :src="getItem(item.services_img)"
                        alt="image"
                      />
                    </div>
                    <div class="featured-bottom">
                      <div class="featured-desc services-text">
                        <p>
                          {{ item.desc }}
                        </p>
                      </div>
                      <div class="fea-btn">
                        <router-link to="/shiprepairs" tabindex="-1"
                          ><i class="ti ti-angle-right"></i
                        ></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from '../Data/data.json'
import $ from "jquery";
export default {
  data() {
    return {
      serviceData: ""
    };
  },
  created(){
    this.serviceData = data.services_with_slik_slide
  },
  mounted() {
    $(".slick_slider").slick({
      speed: 1000,
      slidesToShow: 4,
      infinite: true,
      arrows: false,
      dots: true,
      autoplay: true,
      slidesToScroll: 1,
      // centerMode : false,

      responsive: [
        {
          breakpoint: 1360,
          settings: {
            slidesToShow: 3,
            autoplay: true,
            infinite: true,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            autoplay: true,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            autoplay: true,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            autoplay: true,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },
  methods: {
    getItem(pic) {
      if(pic) {
        return require("../assets/images/services/shiprepairs/" + pic)
      } else {
        return null
      }
    }
  }
};
</script>
