<template>
  <section class="cmt-row about_fid-section clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-10">
          <div class="pt-10 res-991-pt-0">
            <div class="section-title">
              <div class="title-header">
                <h5>{{ wasteManagementData.subtitle }}</h5>
                <h2 class="title">
                  {{ wasteManagementData.title }}
                </h2>
              </div>
              <div class="heading-seperator"><span></span></div>
            </div>
            <p v-for="item in wasteManagementData.desc" :key="item.id">
              {{ item.row }}
            </p> 
            <div class="row mt-20 mb-10">
              <div class="col-lg-6 col-md-5 col-sm-6">
                <ul class="cmt-list cmt-list-style-icon">
                  <li v-for="item in wasteManagementData.characteristics.characteristics_obj1" :key="item.id">
                    <i class="fa fa-arrow-circle-right"></i>
                    <div class="cmt-list-li-content">
                      {{ item.row }}
                    </div>
                  </li> 
                </ul>
              </div>
              <div class="col-lg-6 col-md-5 col-sm-6">
                <ul class="cmt-list cmt-list-style-icon">
                  <li v-for="item in wasteManagementData.characteristics.characteristics_obj2" :key="item.id">
                    <i class="fa fa-arrow-circle-right"></i>
                    <div class="cmt-list-li-content">
                      {{ item.row }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="btnWrapper mt-15 ml-15" v-if="routeName == true">
                <router-link
                  class="cmt-btn cmt-btn-size-sm cmt-btn-shape-square cmt-btn-style-border cmt-btn-color-dark mt-15"
                  to="/wastemanagement"
                  >Read More</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-7 col-sm-9 mt-0 mx-auto mt-15">
          <div class="ttm_single_image-wrapper res-991-pt-30 res-991-pb-30">
            <img
              class="img-fluid"
              :src="getItem(wasteManagementData.image)"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt_60 res-991-mt-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div
              class="cmt-col-bgcolor-yes cmt-bgcolor-white cmt-bg pt-10 pr-10"
            >
              <div class="cmt-col-wrapper-bg-layer cmt-bg-layer">
                <div class="cmt-col-wrapper-bg-layer-inner"></div>
              </div>
              <div class="layer-content cmt-bgcolor-grey">
                <div class="row cmt-vertical_sep">
                  <div class="col-lg-4 col-md-6 col-sm-6 cmt-box-col-wrapper">
                    <div
                      class="cmt-fid inside cmt-fid-with-icon cmt-fid-view-lefticon"
                    >
                      <div class="cmt-fid-icon-wrapper cmt-textcolor-skincolor">
                        <i class="ti ti-user"></i>
                      </div>
                      <div class="cmt-fid-contents">
                        <h4 class="cmt-fid-inner">
                          <span
                            data-appear-animation="animateDigits"
                            data-from="0"
                            data-to="143"
                            data-interval="15"
                            data-before=""
                            data-before-style="sup"
                            data-after=""
                            data-after-style="sub"
                            class="numinate"
                            >143
                          </span>
                          <sub>+</sub>
                        </h4>
                        <h3 class="cmt-fid-title">Happy clients</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-6 cmt-box-col-wrapper">
                    <div
                      class="cmt-fid inside cmt-fid-with-icon cmt-fid-view-lefticon"
                    >
                      <div class="cmt-fid-icon-wrapper cmt-textcolor-skincolor">
                        <i class="flaticon-shipping-and-delivery"></i>
                      </div>
                      <div class="cmt-fid-contents">
                        <h4 class="cmt-fid-inner">
                          <span
                            data-appear-animation="animateDigits"
                            data-from="0"
                            data-to="780"
                            data-interval="15"
                            data-before=""
                            data-before-style="sup"
                            data-after=""
                            data-after-style="sub"
                            class="numinate"
                            >120
                          </span>
                          <sub>+</sub>
                        </h4>
                        <h3 class="cmt-fid-title">Our Machines</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-6 cmt-box-col-wrapper">
                    <div
                      class="cmt-fid inside cmt-fid-with-icon cmt-fid-view-lefticon"
                    >
                      <div class="cmt-fid-icon-wrapper cmt-textcolor-skincolor">
                        <i class="flaticon-list"></i>
                      </div>
                      <div class="cmt-fid-contents">
                        <h4 class="cmt-fid-inner">
                          <span
                            data-appear-animation="animateDigits"
                            data-from="0"
                            data-to="675"
                            data-interval="15"
                            data-before=""
                            data-before-style="sup"
                            data-after=""
                            data-after-style="sub"
                            class="numinate"
                            >25
                          </span>
                          <sub>+</sub>
                        </h4>
                        <h3 class="cmt-fid-title">Years of Experience</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from '../../Data/data.json'
export default {
  data() {
    return {
      wasteManagementData: "",
      routeName: false
    };
  },
  created(){
    this.wasteManagementData = data.wastmanagement_about
  },
  mounted(){
    this.getRoute()
  },
  methods: {
    getItem(pic){
      if(pic) {
        return require("../../assets/images/" + pic)
      } else {
        return null
      }
    },
    getRoute(){
      this.routeName = this.$route.name;
      if(this.routeName == "Home"){
        this.routeName = true
      } else {
        this.routeName = false
      }
    }
  }
};
</script>
